import { Heading, Text, Box } from '@chakra-ui/react';

const ErrorPage = (props: { error?: Error }) => {
  if (!props.error) {
    return null;
  }

  return (
    <Box>
      <Heading as="h2" variant="h2">
        Secret does not exist
      </Heading>
      <Heading as="h3" variant="h3">
        This might be caused by any of the following:
      </Heading>
      <Text as="strong">Opened before</Text>
      <Text>
        A secret is restricted to a single view. It might be lost because the
        sender clicked this link before you viewed it.
      </Text>
      <Text as="strong">Broken link</Text>
      <Text>
        The link must match perfectly in order for the decryption to work, it
        might be missing some magic digits.
      </Text>
      <Text as="strong">Expired</Text>
      <Text>
        No secret last forever. All stored secrets will expires and self
        destruct automatically after one week.
      </Text>
      <Heading variant="h3" mt="12">
        Please request a new secret link from the sender to proceed.
      </Heading>
    </Box>
  );
};

export default ErrorPage;

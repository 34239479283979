import { HashRouter as Router } from 'react-router-dom';
import { ChakraProvider, Box } from '@chakra-ui/react';

import { Header } from './shared/Header';
import { Routes } from './Routes';
import { theme } from './theme';

const App = () => {
  // TODO: Removed in future version.
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      for (const registration of registrations) {
        registration.unregister();
      }
    });
  }

  return (
    <ChakraProvider theme={theme}>
      <Router>
        <Header />
        <Box px="60px" margin="0 auto" minHeight="100vh">
          <Routes />
        </Box>
      </Router>
    </ChakraProvider>
  );
};

export default App;

import randomString, { encryptMessage, postSecret } from '../utils/utils';
import { useState } from 'react';
import Result from '../displaySecret/Result';
import {
  Button,
  Heading,
  Textarea,
  FormControl,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Text,
  Box,
  VStack,
  Divider,
  Center,
} from '@chakra-ui/react';
import { Formik, Form, Field } from 'formik';

const CreateSecret = () => {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState({
    password: '',
    uuid: '',
    customPassword: false,
  });

  const onReset = () =>
    setResult({
      password: '',
      uuid: '',
      customPassword: false,
    });

  const onSubmit = async (form, { setErrors }) => {
    // Use the manually entered password, or generate one
    const pw = randomString();
    setLoading(true);
    try {
      const { data, status } = await postSecret({
        expiration: parseInt('604800'), // one week
        message: await encryptMessage(form.secret, pw),
        one_time: true,
      });

      if (status !== 200) {
        throw new Error(data.message);
      } else {
        setResult({
          customPassword: form.password ? true : false,
          password: pw,
          uuid: data.message,
        });
      }
    } catch (e) {
      setErrors({ secret: e.message });
    }
    setLoading(false);
  };

  return result.uuid ? (
    <Result
      password={result.password}
      uuid={result.uuid}
      customPassword={result.customPassword}
      onReset={onReset}
    />
  ) : (
    <VStack spacing="20">
      <Center maxWidth="1200px" flexDirection="column">
        <Formik onSubmit={onSubmit} initialValues={{}}>
          {({ handleSubmit, errors }) => (
            <>
              {errors.secret && (
                <Alert status="error">
                  <AlertIcon />
                  <AlertTitle mr={2}>Something went wrong!</AlertTitle>
                  <AlertDescription>{errors.secret}</AlertDescription>
                </Alert>
              )}
              <VStack spacing="4">
                <Heading as="h2" variant="h2" textAlign="center">
                  Encrypt your username &amp; password details for sharing
                </Heading>
                <Text maxWidth="60ch">
                  Please enter the username, password or other credentials to be
                  shared with Blue Tongue Digital. A unique, one-time link will
                  be generated which can be safely shared via email.
                </Text>

                <Box w="full" onSubmit={handleSubmit} as={Form}>
                  <Field name="secret">
                    {(fieldProps) => (
                      <FormControl id={fieldProps.field.name}>
                        <Textarea
                          {...fieldProps.field}
                          multiline={true}
                          name="secret"
                          fullWidth
                          autoFocus={true}
                          placeholder={
                            'Message to encrypt locally in your browser'
                          }
                          spellCheck="false"
                          data-gramm="false"
                        />
                      </FormControl>
                    )}
                  </Field>
                  <Button
                    type="submit"
                    disabled={loading}
                    isLoading={loading}
                    mt="6"
                    bgColor="secondary.400"
                    color="white"
                    _hover={{
                      bgColor: 'secondary.300',
                    }}
                  >
                    Encrypt Message
                  </Button>
                </Box>
              </VStack>
            </>
          )}
        </Formik>
      </Center>
      <Divider />
      <Center maxWidth="1200px" flexDirection="column">
        <Heading as="h2" variant="h2" textAlign="center">
          About this tool
        </Heading>
        <Text maxWidth="60ch">
          This password sharer tool is designed to make it simple and secure to
          share passwords or other sensitive data with Blue Tongue Digital.
        </Text>
        <Text maxWidth="60ch">
          It generates a one-time use link that is valid for 1 week only. If the
          link is opened or 1 week passes, the link will no longer work and your
          encrypted data is deleted.
        </Text>
        <Text maxWidth="60ch">
          It encrypts your sensitive data in your own browser, so anything
          entered is not stored anywhere in plain text and is only retrievable
          using the one-time link generated. No one (including us) can access
          your password without that link.
        </Text>
      </Center>
    </VStack>
  );
};

export default CreateSecret;

import { useState } from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { backendDomain, decryptMessage } from '../utils/utils';
import Secret from './Secret';
import ErrorPage from './Error';
import { useAsync } from 'react-use';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Heading,
  Center,
  Progress,
  VStack,
} from '@chakra-ui/react';

const fetcher = async (url) => {
  const request = await fetch(url);
  if (!request.ok) {
    throw new Error('Failed to fetch secret');
  }
  const data = await request.json();
  return data.message;
};

const DisplaySecret = () => {
  const { key, password } = useParams();

  const [secret, setSecret] = useState('');
  const [invalidPassword, setInvalidPassword] = useState(false);

  const url = `${backendDomain}/secret/${key}`;

  const { data, error } = useSWR(url, fetcher, {
    shouldRetryOnError: false,
    revalidateOnFocus: false,
  });

  useAsync(async () => {
    return decrypt();
  }, [password, data]);

  const decrypt = async () => {
    if (!data || !password) {
      return;
    }
    try {
      const r = await decryptMessage(data, password, 'utf8');
      setSecret(r.data);
    } catch (e) {
      setInvalidPassword(true);
      return false;
    }
    return true;
  };

  return (
    <Center>
      <Content
        error={error}
        data={data}
        secret={secret}
        password={password}
        invalidPassword={invalidPassword}
      />
    </Center>
  );
};

const Content = ({ error, data, secret, password, invalidPassword }) => {
  if (error) return <ErrorPage error={error} />;
  if (!data)
    return (
      <VStack>
        <Heading as="h3" variant="h2">
          Fetching data...
        </Heading>
        <Progress isIndeterminate w="60ch" h="2" borderRadius="8px" />
      </VStack>
    );
  if (secret) {
    return <Secret secret={secret} />;
  }
  if (password && !secret && !invalidPassword) {
    return (
      <VStack>
        <Heading as="h3" variant="h2">
          Decrypting data...
        </Heading>
        <Progress isIndeterminate w="60ch" h="2" borderRadius="8px" />
      </VStack>
    );
  }

  return (
    <Alert status="error" maxWidth="60ch">
      <AlertIcon />
      <AlertTitle>Something went wrong!</AlertTitle>
      <AlertDescription>Please check your link and try again</AlertDescription>
    </Alert>
  );
};

export default DisplaySecret;

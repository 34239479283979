import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCopyToClipboard } from 'react-use';
import {
  Heading,
  Text,
  Center,
  Box,
  Button,
  HStack,
  Code,
  Kbd,
  useToast,
} from '@chakra-ui/react';

const Result = ({ uuid, password, onReset }) => {
  const [copy, copyToClipboard] = useCopyToClipboard();
  const toast = useToast();

  const onCopy = (content) => {
    copyToClipboard(content);
    toast({
      title: 'Link copied!',
      description: 'The link has been copied to your clipboard',
      status: 'success',
      duration: 9000,
      isClosable: true,
      variant: 'left-accent',
    });
  };

  const base =
    (process.env.PUBLIC_URL ||
      `${window.location.protocol}//${window.location.host}`) + `/#`;
  const short = `${base}/${uuid}`;
  const full = `${short}/${password}`;

  return (
    <Center maxWidth="1200px" margin="0 auto" flexDirection="column">
      <Heading as="h2">Success!</Heading>
      <Text>
        Your sensitive data has been encrypted and saved. The link below can now
        be used to access it.
      </Text>
      <Text>
        Remember that the link can only be used once so do not open the link
        yourself before sending it.
      </Text>
      <Box mt="4">
        <HStack spacing="1">
          <Code
            p="2"
            borderRadius="8px"
            onClick={() => onCopy(full)}
            cursor="pointer"
          >
            {full}
          </Code>
          <Button
            color={copy.error ? 'secondary' : 'primary'}
            variant="contained"
            onClick={() => onCopy(full)}
            w="fit-content"
            p="0"
          >
            <Kbd h="9" w="9" p="2" borderRadius="8px">
              <FontAwesomeIcon icon={faCopy} />
            </Kbd>
          </Button>
        </HStack>
      </Box>
      <Button mt="8" onClick={onReset} variant="link">
        Create a new secret
      </Button>
    </Center>
  );
};

export default Result;

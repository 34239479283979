import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { useCopyToClipboard } from 'react-use';
import {
  Button,
  Heading,
  Text,
  Code,
  VStack,
  Box,
  HStack,
  Kbd,
  useToast,
} from '@chakra-ui/react';

const Secret = ({ secret }) => {
  const [copy, copyToClipboard] = useCopyToClipboard();
  const toast = useToast();

  const onCopy = (content) => {
    copyToClipboard(content);
    toast({
      title: 'Link copied!',
      description: 'The link has been copied to your clipboard',
      status: 'success',
      duration: 9000,
      isClosable: true,
      variant: 'left-accent',
    });
  };

  return (
    <VStack>
      <Heading as="h2" variant="h2">
        Decrypted secret
      </Heading>
      <Text>
        This secret will not be viewable again, make sure to save it somewhere
        secure now!
      </Text>
      <Box mt="4">
        <HStack spacing="1">
          <Code
            p="2"
            borderRadius="8px"
            onClick={() => onCopy(secret)}
            cursor="pointer"
            whiteSpace="pre-line"
          >
            {secret}
          </Code>
          <Button
            color={copy.error ? 'secondary' : 'primary'}
            variant="contained"
            onClick={() => onCopy(secret)}
            w="fit-content"
            p="0"
          >
            <Kbd h="9" w="9" p="2" borderRadius="8px">
              <FontAwesomeIcon icon={faCopy} />
            </Kbd>
          </Button>
        </HStack>
      </Box>
    </VStack>
  );
};

export default Secret;

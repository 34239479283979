import { extendTheme } from '@chakra-ui/react';
import components from './components';

const colors = {
  brand: {
    400: '#087cff',
  },
  primary: {
    300: '#087cffcc',
    400: '#087cff',
  },
  secondary: {
    300: '#eb5e55cc',
    400: '#eb5e55',
  },
};

export const theme = extendTheme({
  colors,
  components,
});

export default theme;

import { Flex, Image } from '@chakra-ui/react';

export const Header = () => {
  return (
    <Flex
      w="full"
      h="80px"
      p="4"
      pl="60px"
      mb="12"
      justifyContent="flex-start"
      boxShadow="0px 10px 20px 0px rgb(0 0 0 / 10%)"
    >
      <Image src="/logo.png" />
    </Flex>
  );
};

const components = {
  Heading: {
    baseStyle: {
      mb: 4,
    },
    variants: {
      h1: {
        fontSize: '3xl',
      },
      h2: {
        fontSize: '2xl',
      },
      h3: {
        fontSize: 'xl',
      },
    },
  },
  Button: {
    baseStyle: {
      borderRadius: 0,
    },
  },
  Text: {
    baseStyle: {
      maxWidth: '60ch',
      mb: '4',
    },
  },
};

export default components;
